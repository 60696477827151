import gql from 'graphql-tag';

export const GET_ALL_POST = gql`
  query getAllPost($LIMIT: Int, $page: Int) {
    posts(limit: $LIMIT, page: $page) {
      data {
        id
        additionalType
        certified
        title
        media
        photos
        price
        category
        town
        createdAt
        updatedAt
        sid
        userName
      }
      total
    }
  }
`;
