import React from "react";
import Icon from "react-icons-kit";
import { useTranslation } from "next-i18next";
import Tooltip from "reusecore/src/elements/Tooltip";
import {ic_verified_user} from 'react-icons-kit/md/ic_verified_user'

export const Certified = ({certified, size, style}) => {
    const { t } = useTranslation();

    return (
        <>
        {
            certified && (
                <Tooltip
                    message={t('profile.certified.badge')}
                    position={'top'}
                    bubbleStyle={{
                        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
                    }}
                >
                    <Icon
                        icon={ic_verified_user}
                        size={size}
                        style={style}
                    />
                </Tooltip>
            )
        }
        </>
    )
}
