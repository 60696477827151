import gql from 'graphql-tag';

export const SAVE_ALERTS = gql`
  mutation($alert: alertInput!) {
    saveAlert(input: $alert) {
      querystring
      filter
      title
    }
  }
`;

export const DEL_ALERT = gql`
  mutation($alert: idsInput!) {
    deleteAlert(input: $alert) {
      success
    }
  }
`;

export const UPDATE_ALERT = gql`
  mutation($data: alertUpdateInput!) {
    updateAlert(input: $data) {
      success
      message
    }
  }
`;
