import gql from 'graphql-tag';

export const ADD_POST = gql`
  mutation AddPost($post: postInput!) {
    addPost(input: $post) {
      id
      createdAt
      certified
      title
      price
      status
      category
      description
      media
      photos
      sid
      town
      userId
      userName
      additionalType
      alternateName
      mobile
      deleted
      subInfos
    }
  }
`;

export const BOOST_POST = gql`
  mutation BoostPost($postId: ID!) {
    boostPost(input: $postId) {
      success
      message
      data
      extra
    }
  }
`;

export const DEL_POSTS = gql`
  mutation($post: idsInput!) {
    deletePosts(input: $post) {
      success
    }
  }
`;
