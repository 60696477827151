import React from 'react';
import Logo from '../Logo';
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Text from 'reusecore/src/elements/Text';
import { useTranslation } from 'next-i18next';

const logoImage = require('@core/ui/static/images/logo/yatoo-vertical.' +
  process.env.APP_LOCALIZATION +
  '.svg');

const AuthHeader = ({ image, title, description, ...props }) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      {image && (
        <Logo
          style={{ height: '105px', margin: '17px auto' }}
          title={t(`${process.env.APP_LOCALIZATION}:country.brand`) + ' Logo'}
          height={105}
          width={200}
          {...image}
        />
      )}
      {title && (
        <Heading
          as="h1"
          content={t(title.content)}
          style={{
            fontSize: '1.5em',
            marginTop: 20,
            textAlign: 'center',
            fontWeight: 600,
          }}
        />
      )}
      {description && (
        <Text
          content={t(description.content)}
          translate
          style={{
            textAlign: 'center',
            lineHeight: '1.5',
          }}
        />
      )}
    </Box>
  );
};

AuthHeader.defaultProps = {
  mb: '50px',
  image: {
    logoSrc: logoImage,
    auto: true,
  },
  title: {
    content: `${process.env.APP_LOCALIZATION}:login.body.title`,
  },
  description: {
    content: `${process.env.APP_LOCALIZATION}:login.body.baseline`,
  },
};

export default AuthHeader;
