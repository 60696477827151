import gql from 'graphql-tag';

export const ADD_REPORT = gql`
  mutation AddReport($report: reportInput) {
    addReport(input: $report) {
      id
      from
      userId
      adsTitle
      sid
      reason
      extra
    }
  }
`;

export const ADD_REPORT_USER = gql`
  mutation AddReportUser($reportUser: reportUserInput) {
    addReportUser(input: $reportUser) {
      id
      adId
      extra
      messageId
      reason
      userId
      userReported
    }
  }
`;
