import React, {
  useReducer,
  createContext,
  useEffect,
  useState,
  useContext,
} from 'react';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';

import { useToasts } from 'react-toast-notifications';
import { LOGIN, UPDATE_AUTHOR } from '@core/ui/graphql/Mutations';

import AuthHelper from '../helpers/authHelper';
import {
  getNextLocaleCookie,
  removeFirebaseCookie,
  setFirebaseCookie,
  setNextLocaleCookie,
} from '../helpers/session';
import { useAuth } from './AuthProvider';
import { analytics, db } from '../helpers/firebaseHelper';

const initState = {
  additionalType: '',
  address: {},
  alternateName: '',
  avatar: '',
  boost: 0,
  certified: false,
  description: '',
  disableNotif: false,
  favorites: [],
  geo: {},
  image: null,
  lang: '',
  mail: '',
  mobile: '',
  name: '',
  packages: {},
  prenom: '',
  proBoost: 0,
  role: null,
  spam: false,
  town: '',
  unreadMsg: 0,
  userId: '',
  userName: '',
  website: '',
};

const logout = async () => {
  await AuthHelper.logout();
};

export default function UserReducer(state, action) {
  const {
    payload: {
      additionalType,
      address,
      alternateName,
      avatar = '',
      boost = 0,
      certified = false,
      description,
      disableNotif = false,
      favorites = [],
      geo,
      image = null,
      lang = '',
      mail = '',
      mobile = '',
      name = '',
      packages,
      prenom = '',
      proBoost = 0,
      role = '',
      spam = false,
      town = '',
      unreadMsg = 0,
      userId = '',
      userName = '',
      website,
    } = {},
    type,
  } = action;

  if (spam === true) {
    logout().then(() => {
      return initState;
    });
  }

  switch (type) {
    case 'UPDATE_USER': {
      return {
        ...state,
        additionalType,
        address,
        alternateName,
        avatar,
        boost,
        certified,
        description,
        disableNotif,
        favorites,
        geo,
        image,
        lang,
        mail,
        mobile,
        name,
        packages,
        prenom,
        proBoost,
        role,
        spam,
        town,
        unreadMsg,
        userId,
        userName,
        website,
      };
    }
    default:
      return state;
  }
}

export const UserContext = createContext({
  userInfos: null,
});

let currentPath;

export const UserProvider = ({ children }) => {
  const [userInfos, setUserInfos] = useState(null);

  const [state, dispatch] = useReducer(UserReducer, initState);

  const [loginMutation] = useMutation(LOGIN);
  const [authorMutation] = useMutation(UPDATE_AUTHOR);

  const { isLoggedIn, token, user: firebaseUser } = useAuth();

  const router = useRouter();

  const { addToast } = useToasts();

  const { asPath, locale, locales } = router;

  const changeAppLang = async lang => {
    if (isLoggedIn) {
      const {
        avatar = '',
        disableNotif,
        favorites,
        image,
        mail,
        mobile,
        name,
        alternateName = '',
        prenom,
        userId: id,
      } = state;
      const newUser = {
        disableNotif,
        favorites,
        id,
        image,
        imageProfil: avatar,
        lang,
        mail,
        mobile,
        name,
        alternateName,
        prenom,
      };

      await authorMutation({
        variables: {
          author: newUser,
        },
      });
    } else {
      setLang(lang);
    }
  };

  const setLang = lang => {
    const cookieLang = getNextLocaleCookie();

    // check if lang is available
    const desiredLang = locales.find(element => element === lang)
      ? lang
      : locale;

    // set analytics user language
    if (typeof window !== 'undefined' && analytics) {
      analytics.setUserProperties('language', desiredLang);
    }

    if (desiredLang !== cookieLang) {
      // redirect
      router.push(currentPath, currentPath, { locale: desiredLang });
    }
  };

  useEffect(() => {

    setNextLocaleCookie(locale);
    moment.locale(locale);
  }, [locale]);

  useEffect(() => {

    let handle;

    if (token !== null && firebaseUser !== null) {
      const { email } = firebaseUser;

      // get user
      loginMutation({
        variables: { user: { mail: email, token } },
      })
        .then(res => {
          const {
            data: { login },
          } = res;

          const { userId } = login;

          // get user
          handle = db
            .collection('users')
            .doc(userId)
            .onSnapshot(async snapshot => {
              const data = snapshot.data();

              const {
                additionalType,
                alternateName = '',
                address,
                boost,
                description,
                certified,
                disableNotif,
                favorites,
                geo,
                image,
                imageProfil: avatar,
                lang = null,
                mail,
                mobile,
                name,
                packages,
                prenom,
                proBoost,
                role,
                spam = null,
                town,
                unreadMsg,
                uid: userId,
                website,
              } = data || {};

              setLang(lang);

              const userName =
                alternateName !== '' ? alternateName : prenom + ' ' + name;

              const userInfosAfter = { ...login, isSpam: spam, lang };
              setFirebaseCookie('user', userInfosAfter);

              setUserInfos(userInfosAfter);

              dispatch({
                type: 'UPDATE_USER',
                payload: {
                  additionalType,
                  alternateName,
                  address,
                  avatar,
                  boost,
                  description,
                  certified,
                  disableNotif,
                  favorites,
                  geo,
                  image,
                  lang,
                  mail,
                  mobile,
                  name,
                  packages,
                  prenom,
                  proBoost,
                  role,
                  spam,
                  town,
                  unreadMsg,
                  userId,
                  userName,
                  website,
                },
              });
            });
        })
        .catch(error => {
          addToast('Getting User: ' + error.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    } else {
      removeFirebaseCookie('user');

      dispatch({
        type: 'UPDATE_USER',
        payload: initState,
      });

      setUserInfos(null);
    }

    return () => {
      if (handle instanceof Function) {
        handle();
      }
    };
  }, [token]);

  useEffect(() => {
    currentPath = asPath;
  }, [asPath]);

  return (
    <UserContext.Provider value={{ state, dispatch, changeAppLang, userInfos }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};

export const withUser = Child => props => (
  <UserContext.Consumer>
    {context => <Child {...props} {...context} />}
  </UserContext.Consumer>
);
