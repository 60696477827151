import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

const StyledImage = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;
export default function NoItemFound() {
  const { locale } = useRouter();

  const noresult = require('@core/ui/static/images/no-result-' +
    locale +
    '.svg');
  return <StyledImage src={noresult} />;
}
