import { closeModal, Modal, openModal } from '@redq/reuse-modal';
import React, { useEffect, useState } from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Icon from 'react-icons-kit';
import Link from 'next/link';
import { locked } from 'react-icons-kit/iconic/locked';
import Text from 'reusecore/src/elements/Text';
import Button from 'reusecore/src/elements/Button';
import AuthHeader from '../../components/AuthHeader';
import Input from '../../components/Input';
import Box from 'reusecore/src/elements/Box';
import authHelper from '../../helpers/authHelper';
import { analytics } from '../../helpers/firebaseHelper';
import {
  FormGroup,
  ErrorNotification,
  LoginProviderLoader,
  Separator,
  ResendLink,
  ResendSended,
  ResendError,
} from './style';

import Translate from '../../helpers/translateHelper';
import { useTranslation } from 'next-i18next';
import * as Sentry from '@sentry/browser';
import { useRouter } from 'next/router';
import { useAuth } from '../../contexts/AuthProvider';
import { useUser } from '../../contexts/UserContext';

import AppleSignin from 'react-apple-signin-auth';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

import AppleLogo from '@core/ui/static/images/auth/apple_logo_white.png?resize&sizes[]=25';
import FacebookLogo from '@core/ui/static/images/auth/facebook_logo_white.png?resize&sizes[]=25';
import GoogleLogo from '@core/ui/static/images/auth/google_logo.png?resize&sizes[]=25';
import AuthHelper from "../../helpers/authHelper";

const SignInEnhancher = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({ mail: '', password: '', remember: false }),
  validationSchema: Yup.object().shape({
    mail: Yup.string()
      .email('validation.mail.invalid')
      .required('validation.mail.missing'),
    password: Yup.string().required('validation.password.missing'),
  }),
});

const SignInForm = props => {
  const {
    callback,
    values,
    handleChange,
    touched,
    errors,
    modal = false,
    handleBlur,
    setFieldTouched,
    location,
    fromDraft
  } = props;

  const { t } = useTranslation();
  const [error, setError] = useState({});
  const [resendMail, setResendMail] = useState(null);
  const [loading, setLoading] = useState(false);

  const [appleNonce, setAppleNonce] = useState({ hash: '', nonce: '' });

  const { hash, nonce } = appleNonce;

  const router = useRouter();

  const { locale } = router;

  const { login: firebaseLogin } = useAuth();

  const { userInfos } = useUser();
  const resendLink = user => {
    user
      .sendEmailVerification()
      .then(() => {
        setResendMail(<ResendSended>{t('signin.error.send')}</ResendSended>);
      })
      .catch(err => {
        Sentry.captureEvent(err);
        setResendMail(<ResendError>{t('signin.error.error')}</ResendError>);
      });
  };

  const handleSubmit = async () => {
    setFieldTouched('mail', true);
    setFieldTouched('password', true);
    setLoading(true);
    if (!Object.keys(errors).length) {
      const { mail, password } = values;
      const provider = 'password';
      const { user, error } = await firebaseLogin(provider, mail, password);
      if (user) {
        if (callback) {
            const userData =  await AuthHelper.getUserData(user.uid);
            callback(user, userData);
        } else {
          if (!user.emailVerified) {
            setError({
              message: t('emailVerification'),
            });
            setResendMail(
                <ResendLink href="#" onClick={() => resendLink(user)}>
                  {t('signin.error.resend')}
                </ResendLink>
            );
            setLoading(false);
          } else {
            // add analytics login event
            if (typeof window !== 'undefined' && analytics) {
              analytics.logEvent('login', {method: provider});
            }
          }
        }
      } else if (error) {
        setLoading(false);
        const message = Translate(error.message);
        setError({
          message: message ? message : error.message,
        });
      }
    } else {
      setLoading(false);
    }
  };

  const closeComponent = () => {
    return null;
  };

  const modalComponent = props => {
    const { provider } = props;

    const title = t('signin.provider.waitingFor') + ' ' + provider;

    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <p>{title}</p>
        <LoginProviderLoader />
      </div>
    );
  };

  const showModal = provider => {
    openModal({
      config: {
        disableDragging: true,
        transition: {
          tension: 150,
        },
      },
      withRnd: false,
      modalClass: 'customModal',
      closeOnClickOutside: false,
      closeComponent,
      component: modalComponent,
      componentProps: { provider },
    });
  };

  const onAppleResponse = async response => {
    if (response === null) {
      return;
    }

    let {
      authorization: { id_token } = {},
      error,
      user: { name: { firstName, lastName } = {} } = {},
    } = response;

    if (error) {
      if (error !== 'popup_closed_by_user') {
        if (error === 'popup_blocked_by_browser') {
          error = t('signin.error.popupBlocked');
        } else {
          console.error('Apple error => ', error);
          Sentry.captureException(error);
        }
        setError({ message: error });
      }
      closeModal();
      return;
    }

    //// retrieve email everytime to avoid null value in some cases
    // get JWT payload
    const payload = JSON.parse(atob(id_token.split('.')[1]));

    const { email } = payload;

    await loginToProvider(
      'apple',
      email.toLowerCase(),
      firstName,
      lastName,
      locale,
      id_token,
      null,
      nonce
    );
  };

  const onFacebookResponse = async response => {
    const {
      accessToken: token,
      email,
      error,
      first_name,
      grantedScopes,
      last_name,
      status,
    } = response;

    if (error) {
      if (error !== 'popup_closed_by_user') {
        console.error('Facebook error => ', error);
        setError({ message: error });
        Sentry.captureException(error);
      }
      closeModal();
      return;
    }

    if (status) {
      console.error('status => ', status);
      setError({ message: error });
      closeModal();
      return;
    }

    //// check if email granted
    if (!grantedScopes.split(',').includes('email')) {
      console.error('email not granted');
      setError({ message: t('signin.error.providerPermission') });
      closeModal();
      return;
    }

    await loginToProvider(
      'facebook',
      email,
      first_name,
      last_name,
      locale,
      token
    );
  };

  const onGoogleResponse = async response => {
    const {
      accessToken,
      details,
      error,
      profileObj: { email, familyName, givenName } = {},
      tokenId,
    } = response;

    if (error) {
      if (error === 'idpiframe_initialization_failed') {
        return;
      }

      if (error !== 'popup_closed_by_user') {
        console.error('Google error => ', error, details);
        setError({ message: details });
        Sentry.captureException({ error, details });
      }
      closeModal();
      return;
    }

    await loginToProvider(
      'google',
      email,
      givenName,
      familyName,
      locale,
      tokenId,
      accessToken
    );
  };

  const loginToProvider = async (
    provider,
    email,
    firstname,
    lastname,
    localeUser,
    token,
    accessToken,
    nonce
  ) => {
    const login = await authHelper.loginProvider(
      provider,
      email,
      firstname,
      lastname,
      localeUser,
      token,
      accessToken,
      nonce,
      t
    );

    const { error: errorLogin, success, isSpam = false } = login;

    if (isSpam) {
      setError({
        message: t(
          `${process.env.APP_LOCALIZATION}:register.spam.spamValidation`
        ),
      });
    } else if (success === false) {
      const { code, message } = errorLogin;
      let errorMessage = message;

      if (code === 'auth/account-exists-with-different-credential') {
        errorMessage = t('signin.error.providerAccountExist');
      } else {
        Sentry.captureException(errorLogin);
      }

      setError({ message: errorMessage });
    }

    closeModal();
  };

  useEffect(() => {
    if (userInfos !== null) {
      const { isSpam } = userInfos;
      if (isSpam === true) {
        setError({
          message: t(
            `${process.env.APP_LOCALIZATION}:register.spam.spamValidation`
          ),
        });
        setLoading(false);
      } else {
        if (modal) {
          // force reload
          window.location.href = location;
        } else {
          // redirect
          router.push(location);
        }
      }
    }
  }, [userInfos]);

  useEffect(async () => {
    // generate nonce and sha-256 hash
    const nonce = Math.random()
      .toString(36)
      .substring(2, 10);
    const hashBuffer = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(nonce)
    );
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    setAppleNonce({ hash, nonce });
  }, []);

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  }

  return (
    <>
      {/* auth page header section */}
      {
        !fromDraft && (
          <AuthHeader />
        )
      }
      <Modal />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          height: 200,
        }}
      >
        <FacebookLogin
          appId={process.env.AUTH_FACEBOOK_APP_ID}
          disableMobileRedirect={true}
          fields="name,email,first_name,last_name"
          callback={onFacebookResponse}
          scope="public_profile,email"
          returnScopes={true}
          authType="rerequest"
          render={props => (
            <Button
              title={t('signin.provider.continueWith') + ' Facebook'}
              width={1}
              mt={35}
              type="submit"
              style={{ backgroundColor: '#1877F2', marginTop: 0 }}
              icon={
                <img
                  src={FacebookLogo}
                  height={25}
                  width={25}
                  alt="Facebook logo"
                  style={{ marginRight: 5 }}
                />
              }
              iconPosition="left"
              onClick={event => {
                showModal('Facebook');
                props.onClick(event);
              }}
            />
          )}
        />

        <GoogleLogin
          clientId={process.env.AUTH_GOOGLE_CLIENT_ID}
          prompt="consent"
          offline={false}
          onSuccess={onGoogleResponse}
          onFailure={onGoogleResponse}
          render={renderProps => (
            <Button
              title={t('signin.provider.continueWith') + ' Google'}
              width={1}
              mt={35}
              type="submit"
              disabled={renderProps.disabled}
              style={{
                backgroundColor: '#FFF',
                marginTop: 0,
                border: '1px solid #CCC',
                color: '#000',
              }}
              icon={
                <img
                  src={GoogleLogo}
                  height={25}
                  width={25}
                  alt="Google logo"
                  style={{ marginRight: 5 }}
                />
              }
              iconPosition="left"
              onClick={event => {
                showModal('Google');
                renderProps.onClick(event);
              }}
            />
          )}
        />

        <AppleSignin
          authOptions={{
            clientId: process.env.AUTH_APPLE_CLIENT_ID,
            redirectURI: process.env.SITE_URL + '/signin',
            scope: 'email name',
            nonce: hash,
            usePopup: true,
          }}
          onSuccess={onAppleResponse}
          onError={onAppleResponse}
          render={props => (
            <Button
              title={t('signin.provider.continueWith') + ' Apple'}
              width={1}
              mt={35}
              type="submit"
              style={{
                backgroundColor: '#000',
                marginTop: 0,
                border: '1px solid #CCC',
                color: '#FFF',
              }}
              icon={
                <img
                  src={AppleLogo}
                  height={25}
                  width={25}
                  alt="Apple logo"
                  style={{ marginRight: 5 }}
                />
              }
              iconPosition="left"
              onClick={event => {
                showModal('Apple');
                props.onClick(event);
              }}
            />
          )}
        />
      </div>

      <Separator>{t('signin.or')}</Separator>

      {/* signin form */}
      <FormGroup className={errors.mail ? 'hasErrorMsg' : ''}>
        <Input
          elementType="input"
          elementConfig={{
            type: 'email',
            required: 'required',
          }}
          tabIndex="1"
          onBlur={handleBlur('mail')}
          value={values.mail}
          error={errors.mail}
          touched={touched}
          label={t('Email')}
          changed={handleChange('mail')}
        />
        <span className="errorMsg">
          {errors.mail && touched.mail && t(errors.mail)}
        </span>
      </FormGroup>
      <FormGroup className={errors.password ? 'hasErrorMsg' : ''}>
        <div onKeyDown={handleKeyDown}>
          <Input
            elementType="input"
            elementConfig={{
              type: 'password',
              required: 'required',
            }}
            value={values.password}
            tabIndex="2"
            error={errors.password}
            touched={touched.password}
            label={t('Password')}
            onBlur={() => setFieldTouched('password', true)}
            changed={handleChange('password')}
          />
        </div>
        <span className="errorMsg">
          {errors.password && touched.password && t(errors.password)}
        </span>
      </FormGroup>

      {error.message ? (
        <Box
          flexBox
          mt={25}
          mb={15}
          justifyContent="center"
          alignItems="center"
        >
          <ErrorNotification>{error.message}</ErrorNotification>
        </Box>
      ) : (
        ''
      )}

      {resendMail}

      <Button
        onClick={handleSubmit}
        isLoading={loading}
        loaderColor="#fff"
        title={t('login')}
        className={'primary'}
        width={1}
        mt={35}
        type="submit"
        icon={<Icon icon={locked} size={16} />}
      />

      {
        !fromDraft && (
            <>
              <Box flexBox mb={25} mt={25} justifyContent="center">
                <Link href="/forget-password">
                  <a
                      style={{ marginLeft: '10px', fontSize: '12px', minHeight: 'auto' }}
                  >
                    {t('lostPassword')}
                  </a>
                </Link>
              </Box>

              <Box flexBox mt={0} justifyContent="center" alignItems="center">
                <Text content={t('signin.create')} color="#8C8C8C" mb="0" />
                <Link href="/signup">
                  <a style={{ marginLeft: '10px' }}>{t('signin.register')}</a>
                </Link>
              </Box>
            </>
          )
      }
    </>
  );
};

SignInForm.defaultProps = {
  location: '/',
};

export default SignInEnhancher(SignInForm);
