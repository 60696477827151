import { NetworkStatus, useQuery } from '@apollo/client';
import { GET_ALL_POST } from '@core/ui/graphql/AllPost.query';
import { SEARCH_PAGE } from '@core/ui/navigation/constant';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useState } from 'react';

import { MdAutorenew } from 'react-icons/md';
import Box from 'reusecore/src/elements/Box';
import Button from 'reusecore/src/elements/Button';
import Heading from 'reusecore/src/elements/Heading';
import ListGrid from 'reusecore/src/elements/ListGrid';
import styled from 'styled-components';
import NoItemFound from '../../../components/NoItemFound';
import OnError from '../../../components/OnError';
import { PostLoader } from '../../../components/Placeholder';
import PostCard from '../../../components/PostCard';
import { CURRENCY } from '../../../Config';

const RefreshIcon = styled(MdAutorenew)`
  cursor: pointer;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
const RecentPost = () => {
  const { t } = useTranslation();

  // QUERY SECTION
  let QUERY_VARIABLES = {
    LIMIT: 24,
  };

  const [loadingMore, toggleLoading] = useState(false);
  const [page, paginate] = useState(1);

  const { data, loading, error, fetchMore, refetch, networkStatus } = useQuery(
    GET_ALL_POST,
    {
      variables: QUERY_VARIABLES,
      ssr: false,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  // Extract Post Data
  const recentPosts = data && data.posts ? data.posts.data : [];
  const postCount = recentPosts.length;
  const totalPost = data && data.posts ? data.posts.total : 0;
  const renderRecentPost = item => {
    const price = item.price > 0 ? item.price : null;
    const title = item.title ? item.title : '';
    const additionalType = item.additionalType ? item.additionalType : '';
    const certified = item.certified ? item.certified : false;
    const images = item.media ? item.media : [];
    const category = item.category ? item.category : '';
    const town = item.town ? item.town : '';
    const updatedAt = item.updatedAt ? item.updatedAt : '';
    const id = item.id ? item.id : '';
    return (
      <PostCard
        currency={CURRENCY}
        title={title}
        additionalType={additionalType}
        certified={certified}
        price={price}
        id={id}
        category={category}
        town={town}
        createdAt={updatedAt}
        imageSrc={images}
        photos={item.photos}
        sid={item.sid}
        userName={item.userName}
      />
    );
  };

  // Error Rendering.
  if (error) return <OnError />;

  return (
    <>
      <Box flexBox justifyContent="space-between" alignItems="center">
        <Box flexBox alignItems="center">
          <Heading
            content={t('home.ads.title')}
            mb={0}
            fontSize={20}
            fontWeight={600}
          />
          <div style={{ width: 22, height: 22, paddingLeft: '1rem' }}>
            <RefreshIcon
              size={22}
              color="#03b2cb"
              onClick={() => refetch()}
              style={
                networkStatus === NetworkStatus.loading ||
                networkStatus === NetworkStatus.refetch
                  ? { animation: 'rotation 1s infinite linear' }
                  : null
              }
            />
          </div>
        </Box>
        <Link href={`/${SEARCH_PAGE}/all`}>
          <a>
            <Button
              title={t('home.ads.filter')}
              color="#8c8c8c"
              fontWeight={500}
              variant="textButton"
            />
          </a>
        </Link>
      </Box>
      <Box mt={10}>
        {!recentPosts ? (
          <NoItemFound />
        ) : (
          <ListGrid
            data={recentPosts}
            totalPost={totalPost}
            postCount={postCount}
            columnWidth={[1, 1 / 2, 1 / 3, 1 / 4]}
            component={renderRecentPost}
            loading={loading ? loading : loadingMore}
            loaderColor="#ffffff"
            placeholder={<PostLoader />}
            limit={QUERY_VARIABLES.LIMIT}
            handleLoadMore={async () => {
              toggleLoading(true);
              paginate(page + 1);
              await fetchMore({
                variables: {
                  page: page + 1,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) {
                    toggleLoading(false);
                    return prev;
                  }
                  if (postCount && totalPost) {
                    if (postCount <= totalPost) {
                      toggleLoading(false);
                      return Object.assign({}, prev, {
                        posts: {
                          data: [
                            ...prev.posts.data,
                            ...fetchMoreResult.posts.data,
                          ],
                          total: totalPost,
                        },
                      });
                    }
                  }
                },
              });
            }}
          />
        )}
      </Box>
    </>
  );
};
export default RecentPost;
