import { isObject } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from 'next-i18next';

export function dateFormat(date, t) {
  if (isObject(date)) {
    date = moment.unix(date._seconds);
  }

  const dateAds = moment(date).tz('Pacific/Noumea')
  const conditionDate = moment(date).clone().tz('Pacific/Noumea')
  const today = moment(Date.now())
    .tz('Pacific/Noumea')
    .startOf('day');

  if (conditionDate.startOf('day').diff(today, 'days') === 0)
  {
    return `${t('todayAt')} ${dateAds.format('HH:mm')}`;
  }
  else if (conditionDate.startOf('day').diff(today, 'days') === -1)
  {
    return `${t('yesterdayAt')} ${dateAds.format('HH:mm')}`;
  }
  else
  {
    const format = dateAds.isSame(today, 'year')
      ? 'DD MMMM  [' + t('at') + '] HH:mm'
      : 'DD MMMM YYYY [' + t('at') + '] HH:mm';
    return dateAds
      .format(format);
  }
}

export function dateFormatAuthor(date, t) {
  if (isObject(date)) {
    date = moment.unix(date._seconds);
  }
  const today = moment(Date.now())
    .tz('Pacific/Noumea')
    .startOf('day');
  if (
    moment(date)
      .tz('Pacific/Noumea')
      .startOf('day')
      .diff(today, 'days') === 0
  ) {
    return `${t('todayAt')} ${moment(date)
      .tz('Pacific/Noumea')
      .format('HH:mm')}`;
  } else if (
    moment(date)
      .tz('Pacific/Noumea')
      .startOf('day')
      .diff(today, 'days') === -1
  ) {
    return `${t('yesterdayAt')} ${moment(date)
      .tz('Pacific/Noumea')
      .format('HH:mm')}`;
  } else {
    const format = 'MMMM YYYY';
    return moment(date)
      .tz('Pacific/Noumea')
      .format(format);
  }
}
