import React from 'react';
import styled, { css } from 'styled-components';
import { keyFramesLogo } from './KeyFrames';
import Link from 'next/link';

const LogoImage = styled.img`
  display: block;
  width: auto;
  height: 50px;
  max-width: 280px;
  @media (max-width: 768px) {
    height: 60px;
  }
  ${props =>
    props.auto &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}
`;

const ALogoWrapper = styled('span')`
  position: relative;
  cursor: pointer;
  display: block;
  overflow: hidden;
  &::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%
    );
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
  }
  &:hover {
    &::before {
      -webkit-animation: ${keyFramesLogo} 0.75s;
      animation: ${keyFramesLogo} 0.75s;
    }
  }
`;

export default function Logo({
  logoSrc,
  logoSrcSet,
  webp,
  sizes,
  title,
  ...props
}) {
  return (
    <Link href="/">
      <a>
        <ALogoWrapper>
          <picture>
            {webp && (
              <source
                srcSet={webp.srcSet}
                sizes={sizes ? sizes : null}
                type="image/webp"
              />
            )}
            <LogoImage
              src={logoSrc}
              srcSet={logoSrcSet}
              sizes={sizes ? sizes : null}
              alt={title}
              {...props}
            />
          </picture>
        </ALogoWrapper>
      </a>
    </Link>
  );
}
