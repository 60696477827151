import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'reusecore/src/elements/Card';
import Heading from 'reusecore/src/elements/Heading';
import Text from 'reusecore/src/elements/Text';
import PlaceholderImage from '@core/ui/static/images/thumb-grid-placeholder.svg';
import icoCat from '@core/ui/static/images/ico-cat.svg';
import icoLocation from '@core/ui/static/images/ico-location.svg';
import icoCalendar from '@core/ui/static/images/ico-calendar.svg';
import heartO from '@core/ui/static/images/ico-fav-mini.svg';
import heart from '@core/ui/static/images/ico-fav-mini-filled.svg';
import styled from 'styled-components';
import Icon from 'react-icons-kit';
import NumberFormat from 'react-number-format';
import { CURRENCY } from '../../Config';
import { useAuth } from '../../contexts/AuthProvider';
import { Counter } from '../../helpers/Counter';
import { dateFormat } from '../../helpers/dateHelper';
import { Carousel } from 'react-responsive-carousel';
import { SEARCH_PAGE } from '@core/ui/navigation/constant';
import Lightbox from 'react-image-lightbox';
import { useUser } from '../../contexts/UserContext';
import { useMutation } from '@apollo/client';
import {
  HANDLE_FAV,
  DELETE_POST,
  BOOST_POST,
} from '@core/ui/graphql/Mutations';
import { openModal } from '@redq/reuse-modal';
import LoginModal from '../../containers/ModalContainer/LoginModal';
import Link from 'next/link';
import LazyLoad from 'react-lazyload';
import { ROLE_ADMIN } from '@core/ui/role/const';
import { androidDelete } from 'react-icons-kit/ionicons/androidDelete';
import { chevronsUp } from 'react-icons-kit/feather/chevronsUp';
import { confirmAlert } from 'react-confirm-alert';
import BoostModal from '../../containers/ModalContainer/BoostModal';
import { analytics, db } from '../../helpers/firebaseHelper';
import getPhotos, { LARGE, SMALL } from '../../helpers/getPhotos';
import { Certified } from "../Ads/certified";
import { SpinnerLoader } from '../Loader/spinnerLoader';
import { useTranslation } from 'next-i18next';
import { slugify } from '../../helpers/urlHandler';

const PostCard = ({
  imageSrc = [],
  additionalType = '',
  title,
  price,
  category,
  town,
  createdAt,
  currency,
  imageStyle,
  titleStyle,
  priceStyle,
  dateStyle,
  sid,
  id,
  userName,
  photos,
  certified,
  ...props
}) => {
  const { t } = useTranslation();

  const [isboosting, setIsBoosting] = useState(false);
  const [currImg, setCurrImg] = useState(0);
  const [viewIsOpen, setViewIsOpen] = useState(false);
  const { isLoggedIn } = useAuth();
  const { state } = useUser();
  const { favorites, userId } = state;
  const favoritesUser = favorites ? favorites : [];

  const postGallery = getPhotos(imageSrc, photos, SMALL);
  let postGalleryLarge = getPhotos(imageSrc, photos, LARGE);

  postGalleryLarge = postGalleryLarge.map(photo => {
    const { thumbnail: { contentUrl } = {} } = photo;
    if (contentUrl) {
      photo.contentUrl = contentUrl;
    }

    return photo;
  });

  const gotoNext = () => {
    setCurrImg((currImg + 1) % postGalleryLarge.length);
  };
  const gotoPrevious = () => {
    setCurrImg(
      (currImg + postGalleryLarge.length - 1) % postGalleryLarge.length
    );
  };
  const closeImgsViewer = () => {
    setViewIsOpen(false);
  };
  const getZoom = () => {
    setViewIsOpen(true);
  };

  const alreadyFavorite = () => {
    const index = favoritesUser.indexOf(id);
    if (index > -1) {
      return true;
    }
    return false;
  };
  const [handleFavMutation] = useMutation(HANDLE_FAV, {
    variables: {
      fav: {
        id: userId,
        postId: id,
      },
    },
  });
  const handleFavourite = async e => {
    e.preventDefault();
    if (isLoggedIn) {
      await handleFavMutation();

      // analytics
      if (!alreadyFavorite() && typeof window !== 'undefined' && analytics) {
        analytics.logEvent('add_to_wishlist', {
          currency: CURRENCY,
          items: [
            {
              item_id: sid,
              item_name: title,
              item_category: category,
              price: price,
            },
          ],
          value: price,
        });

        // Initialize the sharded counter.
        const favoritesCounter = new Counter(
          db.collection('ads').doc(id),
          'stats.favorites'
        );

        // Increment the field "favorites" of the document
        favoritesCounter.incrementBy(1);
      }
    } else {
      openModal({
        config: {
          disableDragging: false,
          minHeight: 'auto',
          width: 'auto',
          height: 'auto',
          enableResizing: true,
          transition: {
            tension: 150,
          },
        },
        springStyle: {
          backgroundColor: '#ffffff',
          overflowY: 'auto',
        },
        modalClass: 'loginModal',
        closeOnClickOutside: true,
        component: LoginModal,
        componentProps: {},
      });
    }
  };

  const [handleDeleteMutation] = useMutation(DELETE_POST, {
    variables: {
      deletepost: {
        id: userId,
        postId: id,
        sid: sid,
      },
    },
  });

  const [handleBoostMutation] = useMutation(BOOST_POST, {
    variables: {
      postId: id,
    },
  });

  const handleDelete = async () => {
    if (isLoggedIn) {
      await handleDeleteMutation();
      props.deleteAds(sid);
    }
  };

  const boostAds = async () => {
    if (canBoost()) {
      setIsBoosting(true);
      const { data } = await handleBoostMutation();
      setIsBoosting(false);
      const res = data.boostPost;

      if (res.success && typeof window !== 'undefined' && analytics) {
        analytics.logEvent('boost_ad', {
          currency: CURRENCY,
          items: [
            {
              id: sid,
              name: title,
              category,
              price,
            },
          ],
          value: price,
        });
      }

      openModal({
        config: {
          className: 'boostModal',
          width: 'auto',
          height: 'auto',
          enableResizing: false,
          disableDragging: true,
          transition: {
            tension: 150,
          },
        },
        closeOnClickOutside: true,
        component: BoostModal,
        componentProps: {
          data: res,
        },
      });
    }
  };

  const deleteItem = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui-delete-modal">
            <h3>{t('adsView.sidebar.delete.modal.title')}</h3>
            <p>{t('adsView.sidebar.delete.modal.msg')}</p>
            <button onClick={onClose}>{t('No')}</button>
            <button
              onClick={() => {
                handleDelete(sid);
                onClose();
              }}
            >
              {t('adsView.sidebar.delete.modal.confirm')}
            </button>
          </div>
        );
      },
    });
  };

  let _getHeaderComponent = () => {
    if (postGallery.length > 1) {
      return (
        <LazyLoad
          placeholder={
            <img src={PlaceholderImage} style={imageStyle} alt={title} />
          }
          offset={300}
          once
          style={{ paddingBottom: 4 }}
        >
          <Carousel
            swipeable={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            onClickItem={getZoom}
            selectedItem={currImg}
            onChange={index => setCurrImg(index)}
          >
            {postGallery.map((image, index) => {
              const {
                contentUrl,
                height = null,
                source = null,
                thumbnail,
                width = null,
              } = image;
              const {
                contentUrl: thumbnailContentUrl,
                height: thumbnailHeight = null,
                source: thumbnailSource = null,
                width: thumbnailWidth = null,
              } = thumbnail || {};

              const imgSrc = thumbnail ? thumbnailContentUrl : contentUrl;
              const imgHeight = thumbnail ? thumbnailHeight : height;
              const imgSource = thumbnail ? thumbnailSource : source;
              const imgWidth = thumbnail ? thumbnailWidth : width;

              return (
                <picture key={index}>
                  {imgSource}
                  <img
                    src={imgSrc}
                    onClick={getZoom}
                    alt={title}
                    style={imageStyle}
                    height={imgHeight}
                    width={imgWidth}
                    loading="lazy"
                  />
                </picture>
              );
            })}
          </Carousel>
        </LazyLoad>
      );
    } else {
      let imgSrc = PlaceholderImage;
      let imgSource = null;

      let imgHeight = null;
      let imgWidth = null;

      if (postGallery.length > 0) {
        const {
          contentUrl,
          height = null,
          source = null,
          thumbnail,
          width = null,
        } = postGallery[0];
        const {
          contentUrl: thumbnailContentUrl,
          height: thumbnailHeight = null,
          source: thumbnailSource = null,
          width: thumbnailWidth = null,
        } = thumbnail || {};

        imgSrc = thumbnail ? thumbnailContentUrl : contentUrl;
        imgHeight = thumbnail ? thumbnailHeight : height;
        imgSource = thumbnail ? thumbnailSource : source;
        imgWidth = thumbnail ? thumbnailWidth : width;
      }

      return (
        <LazyLoad
          placeholder={
            <img src={PlaceholderImage} style={imageStyle} alt={title} />
          }
          offset={300}
          once
        >
          <picture>
            {imgSource}
            <img
              src={imgSrc}
              onClick={postGallery.length > 0 ? getZoom : null}
              alt={title}
              style={imageStyle}
              height={imgHeight}
              width={imgWidth}
              loading="lazy"
            />
          </picture>
        </LazyLoad>
      );
    }
  };
  const ComponentCardHeader = _getHeaderComponent();

  const isAdmin = () => {
    return state.role === ROLE_ADMIN;
  };

  const canDelete = () => {
    return typeof props.deleteAds === 'function' && userId === props.owner;
  };

  const canBoost = () => {
    const boostLimit = (certified) ? 5 : 3;
    return (
      state &&
      state.boost < boostLimit &&
      props.canBoost === true &&
      userId === props.owner
    );
  };

  return (
    <>
      {viewIsOpen && (
        <Lightbox
          mainSrc={postGalleryLarge[currImg].contentUrl}
          nextSrc={
            postGalleryLarge.length > 1
              ? postGalleryLarge[(currImg + 1) % postGalleryLarge.length]
                  .contentUrl
              : ''
          }
          prevSrc={
            postGalleryLarge.length > 1
              ? postGalleryLarge[
                  (currImg + postGalleryLarge.length - 1) %
                    postGalleryLarge.length
                ].contentUrl
              : ''
          }
          onCloseRequest={closeImgsViewer}
          onMovePrevRequest={gotoPrevious}
          onMoveNextRequest={gotoNext}
        />
      )}
      <Card {...props}>
        {additionalType === 'pro' && (
          <div
            style={{
              position: 'absolute',
              zIndex: 1,
              marginTop: 10,
              marginLeft: 10,
              display: 'flex',
            }}
          >
            <span
              style={{
                backgroundColor: '#FFF',
                border: 1,
                borderColor: '#002F3F',
                borderStyle: 'solid',
                paddingLeft: 4,
                paddingRight: 4,
                fontSize: 12,
                color: '#002F3F',
                userSelect: 'none',
              }}
            >
              PRO
            </span>
          </div>
        )}
        {ComponentCardHeader}
        <SubCardWrapper>
          <SubCardInfosWrapper>
            <Link href={`/${SEARCH_PAGE}/${slugify(category)}`}>
              <a style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                <div style={{ display: 'flex', marginRight: '5px' }}>
                  <img
                    src={icoCat}
                    alt={t('category.' + category)}
                    width={15}
                    height={15}
                  />
                </div>
                {category && (
                  <Text
                    mb={0}
                    className="category"
                    content={t('category.' + category)}
                  />
                )}
              </a>
            </Link>
            <Link href={`/${SEARCH_PAGE}/all?town=${town.trim()}`}>
              <a style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    marginRight: '5px',
                    marginLeft: '5px',
                  }}
                >
                  <img
                    src={icoLocation}
                    alt="Localisation"
                    width={12}
                    height={15}
                  />
                </div>
                {town && <Text mb={0} className="town" content={town} />}
              </a>
            </Link>
          </SubCardInfosWrapper>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column', position: 'relative', zIndex: 999 }}>
            <div style={{ alignItems: 'baseline', display: 'flex', paddingRight: 10 }}>
              <Certified certified={certified} size={13} style={{
                color: '#00BCD4',
                marginRight: 5,
              }} />
              {title && (
                <Link
                  href={`/${SEARCH_PAGE}/${slugify(category)}/${slugify(
                    title
                  )}-${sid}`}
                >
                  <a style={{ overflow: 'hidden' }}>
                    <Heading
                      content={title}
                      {...titleStyle}
                      as={props.hn}
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      mb="1"
                    />
                  </a>
                </Link>
              )}
            </div>
            {process.browser && !!price && !!currency && (
              <NumberFormat
                value={price}
                displayType={'text'}
                thousandSeparator={' '}
                renderText={value => (
                  <Text
                    content={`${value} ${currency} `}
                    {...priceStyle}
                    mb="0"
                  />
                )}
              />
            )}
            {isAdmin() && (
              <div style={{ margin: '5px 0' }}>
                <strong style={{ color: '#ccc', fontSize: 12 }}>
                  {userName}
                </strong>
              </div>
            )}
          </div>
          <BottomWrapper>
            <DateWrapper>
              {createdAt && (
                <>
                  <img
                    src={icoCalendar}
                    alt="Date de publication"
                    style={{
                      marginRight: '10px',
                      height: '13px',
                      marginTop: -2,
                    }}
                  />
                  <Text
                    mb={0}
                    mt={0}
                    color={'#595959'}
                    content={dateFormat(createdAt, t)}
                    translate={false}
                    {...dateStyle}
                  />
                </>
              )}
            </DateWrapper>
            <ActionWrapper>
              {canDelete() && (
                <Icon
                  className="delete"
                  title={t('deleteAds')}
                  icon={androidDelete}
                  style={{ color: '#E65B5B', marginRight: '10px' }}
                  fontSize={13}
                  onClick={deleteItem}
                />
              )}
              {canBoost() && isboosting ? (
                <SpinnerLoader
                  color="#0a2e3e"
                  width="13px"
                  height="13px"
                  margin="0px 10px 0 0"
                />
              ) : canBoost() ? (
                <Icon
                  title={t('adsView.sidebar.boost.action')}
                  className="boost"
                  icon={chevronsUp}
                  style={{ color: '#0a2e3e', marginRight: '10px' }}
                  fontSize={13}
                  onClick={boostAds}
                />
              ) : null}
              {alreadyFavorite() ? (
                <img
                  src={heart}
                  alt={t('deleteFavs')}
                  title={t('deleteFavs')}
                  style={{
                    marginRight: '5px',
                    position: 'relative',
                    zIndex: 2,
                  }}
                  color="#00BCD4"
                  onClick={handleFavourite}
                />
              ) : (
                <img
                  src={heartO}
                  alt={t('profile.favorites.add')}
                  title={t('profile.favorites.add')}
                  style={{
                    marginRight: '5px',
                    position: 'relative',
                    zIndex: 2,
                  }}
                  color="#595959"
                  onClick={handleFavourite}
                />
              )}
            </ActionWrapper>
          </BottomWrapper>
          <Link
            href={`/${SEARCH_PAGE}/${slugify(category)}/${slugify(
              title
            )}-${sid}`}
          >
            <a
              title={title}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 0,
              }}
            />
          </Link>
        </SubCardWrapper>
      </Card>
    </>
  );
};

const BottomWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 5px;
  line-height: 0;
`;
const DateWrapper = styled('div')`
  display: flex;
  align-items: center;
`;
const ActionWrapper = styled('div')`
  display: flex;
  align-items: center;
  i {
    margin-top: 0;
  }
`;
const SubCardWrapper = styled('div')`
  padding: 0px 12px 6px 12px;
  min-height: 140px;
  position: relative;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  a {
    color: inherit;
    transition-duration: 0.4s;
    z-index: 500;
    &:hover {
      color: #00bcd4;
    }
  }
  i.boost,
  i.delete {
    transition-duration: 0.4s;
    opacity: 0.7;
    cursor: pointer;
    z-index: 500;
    &:hover {
      opacity: 1;
    }
  }
  i:last-child {
    transition-duration: 0.4s;
    cursor: pointer;
    color: #595959;
    z-index: 500;
    &:hover {
      color: #00bcd4;
    }
  }
  h2 {
    transition-duration: 0.4s;
    cursor: pointer;
    &:hover {
      color: black;
      text-decoration: underline;
    }
  }
`;
const SubCardInfosWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #595959;
  height: 50px;
`;

PostCard.propTypes = {
  category: PropTypes.string.isRequired,
  town: PropTypes.string,
  title: PropTypes.string.isRequired,
  owner: PropTypes.string,
  deleteAds: PropTypes.func,
  imageSrc: PropTypes.array,
  postNumber: PropTypes.string,
  imageStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  dateStyle: PropTypes.object,
  postNumberStyle: PropTypes.object,
};

PostCard.defaultProps = {
  boxShadow: 'none',
  borderRadius: '0px',
  mb: '40px',
  bg: 'white',
  hn: 'h4',
  owner: null,
  deleteAds: null,
  imageStyle: {
    width: '100%',
    height: '210px',
    color: '#fff',
    borderRadius: '0px',
    objectFit: 'cover',
  },
  titleStyle: {
    fontSize: '15px',
    color: '#002F3F',
  },
  priceStyle: {
    color: '#00BCD4',
    fontSize: '15px',
  },
  dateStyle: {
    fontSize: '11px',
  },
};

export default PostCard;
