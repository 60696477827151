import React from 'react';
import SignInForm from '../../SignInForm';

const LoginModal = () => {
  return (
    <div className="NoMargin">
      <SignInForm location={window.location.pathname} modal={true} />
    </div>
  );
};

export default LoginModal;
