let GRAPHQL_URL = '';

// When we run yarn web command
if (process.env.NODE_ENV === 'development') {
  GRAPHQL_URL = process.env.GRAPHQL_URL_LOCAL;
}

// When we run yarn web:staging && yarn serve
if (process.env.NODE_ENV === 'production' && process.env.isLocal) {
  GRAPHQL_URL = process.env.GRAPHQL_URL_DEV;
}

// Used in production after yarn web:build yarn deploy (can also be tested in local with yarn web:build && yarn serve)
if (process.env.NODE_ENV === 'production' && !process.env.isLocal) {
  GRAPHQL_URL = process.env.GRAPHQL_URL;
}

const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY || null;
const CURRENCY = process.env.CURRENCY || '$';

export { GRAPHQL_URL, GOOGLE_API_KEY, CURRENCY };
