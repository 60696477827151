import gql from 'graphql-tag';

export const UPDATE_AUTHOR = gql`
  mutation($author: authorInput!) {
    addAuthor(input: $author) {
      mobile
      imageProfil
      lang
    }
  }
`;
export const HANDLE_FAV = gql`
  mutation HandleFav($fav: adFavInput!) {
    handleFav(input: $fav) {
      data
    }
  }
`;

export const UPDATE_MASS_FAV = gql`
  mutation($fav: favList!) {
    updateMassFav(input: $fav) {
      success
      data
    }
  }
`;

export const DELETE_POST = gql`
  mutation($deletepost: deletePostInput!) {
    deletePost(input: $deletepost) {
      success
    }
  }
`;

export const GET_VERIFICATION_CODE = gql`
  mutation GetVerificationCode($mobileNumber: InputMobileNumber!) {
    getVerificationCode(input: $mobileNumber) {
      id
      code
    }
  }
`;

export const VERIFY_CODE = gql`
  mutation VerifyCode($verify: InputVerifyNumber!) {
    verifyCode(input: $verify) {
      token
    }
  }
`;

export const LOGIN = gql`
  mutation Login($user: LoginInput!) {
    login(input: $user) {
      userId
      mail
      isSpam
      lang
      error
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout($user: RegisterInput!) {
    logout(input: $user) {
      userId
      mail
      error
    }
  }
`;

export const REGISTER = gql`
  mutation Register($user: RegisterInput!) {
    register(input: $user) {
      mail
      isSpam
      error
      lang
    }
  }
`;
export const RESET_PASS = gql`
  mutation ResetPass($resetPass: ResetPassInput!) {
    resetPass(input: $resetPass) {
      userId
      token
    }
  }
`;

export const FOREGET_PASS_MAIL = gql`
  mutation ForgetPassMail($forgetPassMail: ForgetPassMailInput!) {
    forgetPassMail(input: $forgetPassMail) {
      message
    }
  }
`;
