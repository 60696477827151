import React from 'react';
import { BoostModalWapper, LogoWrapper, TextWrapper } from './style';
import Logo from '../../../components/Logo';
import logoImg from '@core/ui/static/images/icon.png';
import { useTranslation } from 'next-i18next';

const BoostModal = props => {
  const { t } = useTranslation();
  const { data } = props;

  return (
    <BoostModalWapper>
      <LogoWrapper>
        <Logo
          logoSrc={logoImg}
          title={t(`${process.env.APP_LOCALIZATION}:country.brand`) + ' Logo'}
        />
      </LogoWrapper>
      <TextWrapper>
        {data.success ? (
          <TextWrapper>
            {t('adsView.sidebar.boost.modal.success')}
            <br />
            <small>
              <i>{t('adsView.sidebar.boost.modal.limit', {total: data.data.boostLimit})}</i>
            </small>
          </TextWrapper>
        ) : data.message ? (
          <TextWrapper>
            {t('adsView.sidebar.boost.modal.error')}
            <br />
            <small>
              <i>{t(data.message, {total: data.data.boostLimit})}</i>
            </small>
          </TextWrapper>
        ) : (
          <TextWrapper>
            <strong>{t('Error')}</strong>
            <br />
            {t('adsView.sidebar.boost.modal.error2')}
          </TextWrapper>
        )}
      </TextWrapper>
    </BoostModalWapper>
  );
};

export default BoostModal;
