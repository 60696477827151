import styled from 'styled-components';

const FormGroup = styled('div')`
  position: relative;
  span.errorMsg {
    position: absolute;
    bottom: -30px;
    right: 0;
    font-size: 12px;
    color: red;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.96);
    transition: all 300ms ease-out;
  }
  &.hasErrorMsg {
    span.errorMsg {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
      bottom: -22px;
      right: 0;
    }
  }
`;

const ErrorNotification = styled('p')`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  width: 100%;
  color: #f15251;
  border-radius: 3px;
`;

const LoginProviderLoader = styled('div')`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 8px solid #00bcd4;
  animation: s10-1 0.8s infinite linear alternate, s10-2 1.6s infinite linear;
  @keyframes s10-1 {
    0% {
      clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }
    12.5% {
      clip-path: polygon(
        50% 50%,
        0 0,
        50% 0%,
        100% 0%,
        100% 0%,
        100% 0%,
        100% 0%
      );
    }
    25% {
      clip-path: polygon(
        50% 50%,
        0 0,
        50% 0%,
        100% 0%,
        100% 100%,
        100% 100%,
        100% 100%
      );
    }
    50% {
      clip-path: polygon(
        50% 50%,
        0 0,
        50% 0%,
        100% 0%,
        100% 100%,
        50% 100%,
        0% 100%
      );
    }
    62.5% {
      clip-path: polygon(
        50% 50%,
        100% 0,
        100% 0%,
        100% 0%,
        100% 100%,
        50% 100%,
        0% 100%
      );
    }
    75% {
      clip-path: polygon(
        50% 50%,
        100% 100%,
        100% 100%,
        100% 100%,
        100% 100%,
        50% 100%,
        0% 100%
      );
    }
    100% {
      clip-path: polygon(
        50% 50%,
        50% 100%,
        50% 100%,
        50% 100%,
        50% 100%,
        50% 100%,
        0% 100%
      );
    }
  }
  @keyframes s10-2 {
    0% {
      transform: scaleY(1) rotate(0deg);
    }
    49.99% {
      transform: scaleY(1) rotate(135deg);
    }
    50% {
      transform: scaleY(-1) rotate(0deg);
    }
    100% {
      transform: scaleY(-1) rotate(-135deg);
    }
  }
`;

const Separator = styled('p')`
  align-items: center;
  display: flex;
  text-align: center;
  &::before,
  &::after {
    border-top: 0.0625em solid #666666;
    content: '';
    flex: 1;
    margin: 0 0.5em;
  }
`;

const SuccessNotification = styled('p')`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  width: 100%;
  border-radius: 3px;
`;

const ResendLink = styled('a')`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  display: block;
  color: #004a52;
  text-decoration: underline;
`;

const ResendSended = styled('span')`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  display: block;
  color: #004a52;
`;
const ResendError = styled('span')`
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  display: block;
  color: #f15251;
`;

export {
  FormGroup,
  ErrorNotification,
  LoginProviderLoader,
  Separator,
  ResendLink,
  ResendSended,
  ResendError,
  SuccessNotification,
};
