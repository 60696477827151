import gql from 'graphql-tag';

export const ADD_REVIEW = gql`
  mutation AddReview($review: reviewInput!) {
    addReview(input: $review) {
      title
      message
      communication
      punctuality
      quality
      global
      status
      reviewedUserId
      reviewedAlternateName
      creatorAlternateName
      creatorCertified
      createdAt
    }
  }
`;
