import { useTranslation } from 'next-i18next';
import React from 'react';
import ContentLoader from 'react-content-loader';

const PostLoader = props => {
  const { t } = useTranslation();

  const loading = t('loading');

  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 264 350"
      primarycolor="#f3f3f3"
      secondarycolor="#ecebeb"
      aria-label={loading}
      title={loading}
      uniqueKey="postLoader"
      {...props}
    >
      <rect x="0" y="2" rx="0" ry="0" width="264" height="210" />
      <rect x="0" y="220" rx="0" ry="0" width="120" height="8" />
      <rect x="194" y="220" rx="0" ry="0" width="70" height="8" />
      <rect x="123" y="123" rx="0" ry="0" width="0" height="0" />
      <rect x="0" y="255" rx="0" ry="0" width="170" height="12" />
      <rect x="0" y="280" rx="0" ry="0" width="100" height="12" />
      <rect x="0" y="320" rx="0" ry="0" width="140" height="6" />
      <rect x="240" y="318" rx="0" ry="0" width="20" height="9" />
    </ContentLoader>
  );
};

const ProfileAvatarLoader = props => {
  const { t } = useTranslation();

  const loading = t('loading');

  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 400 160"
      primarycolor="#f3f3f3"
      secondarycolor="#ecebeb"
      aria-label={loading}
      title={loading}
      uniqueKey="profileAvatarLoader"
      {...props}
    >
      <rect x="142.63" y="122.61" rx="0" ry="0" width="0" height="0" />
      <circle cx="80" cy="80" r="80" />
      <rect x="190" y="50" rx="0" ry="0" width="170" height="20" />

      <rect x="190" y="82" rx="0" ry="0" width="13" height="13" />
      <rect x="213" y="82" rx="0" ry="0" width="180" height="13" />

      <rect x="190" y="107" rx="0" ry="0" width="13" height="13" />
      <rect x="213" y="107" rx="0" ry="0" width="140" height="13" />
    </ContentLoader>
  );
};

const GalleryLoader = props => {
  const { t } = useTranslation();

  const loading = t('loading');

  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 730 850"
      primarycolor="#f3f3f3"
      secondarycolor="#ecebeb"
      aria-label={loading}
      title={loading}
      uniqueKey="galleryLoader"
      {...props}
    >
      <circle cx="86.78" cy="84.61" r="1" />
      <rect x="0" y="0" rx="0" ry="0" width="730" height="650" />
      <rect x="0" y="670" rx="0" ry="0" width="140" height="120" />
      <rect x="160" y="670" rx="0" ry="0" width="140" height="120" />
      <rect x="180" y="670" rx="0" ry="0" width="140" height="120" />
      <rect x="320" y="670" rx="0" ry="0" width="140" height="120" />
      <rect x="480" y="670" rx="0" ry="0" width="140" height="120" />
    </ContentLoader>
  );
};

const ListLoader = props => {
  const { t } = useTranslation();

  const loading = t('loading');

  return (
    <div style={{ marginTop: 5, marginBottom: 5 }}>
      <ContentLoader
        speed={2}
        viewBox="0 0 600 160"
        primarycolor="#f3f3f3"
        secondarycolor="#ecebeb"
        aria-label={loading}
        title={loading}
        uniqueKey="listLoader"
        {...props}
      >
        <rect x="240" y="10" rx="0" ry="0" width="80" height="6" />
        <rect x="520" y="10" rx="0" ry="0" width="80" height="6" />
        <rect x="0" y="0" rx="0" ry="0" width="220" height="170" />
        <rect x="240" y="40" rx="0" ry="0" width="380" height="10" />
        <rect x="515" y="65" rx="0" ry="0" width="90" height="11" />
        <rect x="240" y="100" rx="0" ry="0" width="380" height="8" />
        <rect x="240" y="118" rx="0" ry="0" width="150" height="8" />
        <rect x="575" y="150" rx="0" ry="0" width="20" height="6" />
        <rect x="240" y="150" rx="0" ry="0" width="80" height="6" />
      </ContentLoader>
    </div>
  );
};

const CategoryLoader = props => {
  const { t } = useTranslation();

  const loading = t('loading');

  return (
    <ContentLoader
      height={70}
      width={300}
      speed={2}
      primarycolor="#f3f3f3"
      secondarycolor="#ecebeb"
      aria-label={loading}
      title={loading}
      uniqueKey="categoryLoader"
      {...props}
    >
      <circle cx="55" cy="30" r="30" />
      <circle cx="86.78" cy="84.61" r="1" />
      <rect x="105" y="15" rx="0" ry="0" width="170" height="10" />
      <rect x="105" y="35" rx="0" ry="0" width="70" height="10" />
    </ContentLoader>
  );
};

const CategoryLoaderCard = props => {
  const { t } = useTranslation();

  const loading = t('loading');

  return (
    <ContentLoader
      height={120}
      width={150}
      speed={2}
      primarycolor="#f3f3f3"
      secondarycolor="#ecebeb"
      aria-label={loading}
      title={loading}
      uniqueKey="categoryLoaderCard"
      {...props}
    >
      <circle cx="75" cy="50" r="25" />
      <rect x="25" y="85" rx="0" ry="0" width="100" height="15" />
    </ContentLoader>
  );
};

const ReactTable = props => {
  const { t } = useTranslation();

  const loading = t('loading');

  return (
    <ContentLoader
      className="loading"
      //width      = { 400 }
      //height     = { 90 }
      speed={2}
      viewBox="0 0 400 90"
      aria-label={loading}
      title={loading}
      uniqueKey="reactTable"
      {...props}
    >
      <rect x="0" y="6" rx="1" ry="1" width="8" height="8" />
      <rect x="15" y="4" rx="2" ry="2" width="60" height="12" />
      <rect x="180" y="7.5" rx="1" ry="1" width="40" height="5" />
      <rect x="270" y="8" rx="1" ry="1" width="20" height="5" />

      <rect x="0" y="21" rx="0" ry="0" width="400" height="1" />

      <rect x="0" y="34" rx="1" ry="1" width="8" height="8" />
      <rect x="15" y="31" rx="1" ry="1" width="40" height="5" />
      <rect x="60" y="31" rx="5" ry="5" width="5" height="5" />
      <rect x="15" y="41" rx="0" ry="0" width="25" height="3" />
      <rect x="45" y="41" rx="0" ry="0" width="25" height="3" />
      <rect x="180" y="35.5" rx="1" ry="1" width="40" height="5" />
      <rect x="270" y="30" rx="2" ry="2" width="60" height="15" />
      <rect x="340" y="35.5" rx="1" ry="1" width="20" height="5" />
      <rect x="370" y="35.5" rx="1" ry="1" width="20" height="5" />

      <rect x="0" y="67" rx="1" ry="1" width="8" height="8" />
      <rect x="15" y="65" rx="1" ry="1" width="40" height="5" />
      <rect x="60" y="65" rx="5" ry="5" width="5" height="5" />
      <rect x="15" y="75" rx="0" ry="0" width="25" height="3" />
      <rect x="45" y="75" rx="0" ry="0" width="25" height="3" />
      <rect x="180" y="68.5" rx="1" ry="1" width="40" height="5" />
      <rect x="270" y="62.5" rx="2" ry="2" width="60" height="15" />
      <rect x="340" y="68.5" rx="1" ry="1" width="20" height="5" />
      <rect x="370" y="68.5" rx="1" ry="1" width="20" height="5" />
    </ContentLoader>
  );
};

export {
  PostLoader,
  ProfileAvatarLoader,
  ListLoader,
  CategoryLoader,
  CategoryLoaderCard,
  ReactTable,
};
